import styled from '@emotion/styled'
import { CheckCircle, Gift } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  benefits: string[]
  title?: string
}

export const Benefits = memo(function Benefits({ benefits, title }: Props) {
  return (
    <Container dial={5} row tag="section">
      {title ? (
        <Wrap>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Gift />
        </Wrap>
      ) : null}
      <List row space="between" wrap>
        {benefits.map((item, index) => (
          <Item dial={4} key={index} row>
            <CheckCircle />
            <Label>
              {
                // @ts-ignore
                item.label
              }
            </Label>
          </Item>
        ))}
      </List>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 4.5625rem 3.333vw;

  @media (max-width: 1023px) {
    padding: 3.25rem 2.083vw 1.875rem;
    flex-wrap: wrap;
  }
`

const Wrap = styled.div`
  margin-right: 5.556vw;
  position: relative;

  svg {
    width: auto;
    height: 6.25rem;
    fill: none;
    position: absolute;
    top: 50%;
    left: 50%;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1023px) {
    margin-right: 0;
    margin-bottom: 60px;
    text-align: center;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  position: relative;
  z-index: 2;
`

const List = styled(FlexBox)``

const Item = styled(FlexBox)`
  width: 33.333%;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 0 4.583vw;
  white-space: nowrap;

  svg {
    width: auto;
    height: 1.875rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    margin-top: 0.75rem;
    padding: 0 1.875rem;
    text-align: center;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Label = styled.span`
  width: calc(100% - 2.875rem);
  margin-left: 1rem;

  @media (max-width: 767px) {
    width: auto;
  }
`
